import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Descriptions,
  Divider,
  List,
  Button,
  Modal,
  message,
  Tag,
  Input,
  Collapse,
  Select,
  notification,
} from "antd";
import moment from "moment";
import {
  GetKitchenOrders,
  SendNotification,
  SendExtraNotification,
  setLastProcessedOrder,
} from "../../../Features/kitchenSlice";
import { ChatCircleDots, Printer } from "phosphor-react";
import {
  selectKitchen,
  useAppSelector,
  useAppDispatch,
} from "../../../Store/store";
const { Panel } = Collapse;
const { Option } = Select;

const Orders = () => {
  const dispatch = useAppDispatch();
  const { userData, auth, orders, lastProcessedOrder } =
    useAppSelector(selectKitchen);

  const [currentOrderIndex, setCurrentOrderIndex] = useState(0);
  const [attendedOrders, setAttendedOrders] = useState([]);
  const [doneAndPackagedOrders, setDoneAndPackagedOrders] = useState([]);
  const [isChatModalVisible, setChatModalVisible] = useState(false);
  const [messageInput, setMessageInput] = useState("");
  const [orderNumber, setOrderNumber] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [attendedFilter, setAttendedFilter] = useState(null);
  const audioRef = useRef(null);
  const animationRef = useRef(null);

  const isElizadeUniversity = () => {
    return userData?.University === "Elizade" || userData?.University === "Elizade University";
  };

  useEffect(() => {
    if (!orders) {
      dispatch(GetKitchenOrders(userData?.KitchenEmail));
    }
  }, []);

  const handleAttendedFilterChange = (value) => {
    setAttendedFilter(value);
  };

  const currentDate = moment();
  const today = currentDate.date();

  const filteredOrders = orders?.filter((order) => {
    const orderDate = moment(order.CreatedAt);
    return today === orderDate.date() && order.IsPaid === true;
  });

  var currentOrder;
  if (orders) {
    currentOrder = filteredOrders[currentOrderIndex];
  }

  const handleChatIconClick = () => {
    setChatModalVisible(true);
  };

  const handleModalCancel = () => {
    setChatModalVisible(false);
  };

  const handleInputChange = (e) => {
    setMessageInput(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSendMessage = async (Order) => {
    const updatedOrder = {
      ...Order,
      IsAttended: false,
    };

    let notificationData;

    if (userData && userData.Role === "basic") {
      notificationData = {
        KitchenId: userData.KitchenId,
        Title: `${userData.FirstName} (staff)`,
        UserId: updatedOrder.UserId,
        Message: messageInput,
        OrderId: updatedOrder.TrxRef,
      };
    } else {
      notificationData = {
        KitchenId: userData.Id,
        Title: userData.KitchenName,
        UserId: updatedOrder.UserId,
        Message: messageInput,
        OrderId: updatedOrder.TrxRef,
      };
    }

    dispatch(SendExtraNotification(notificationData, auth));
    // const notificationResponse = dispatch(
    //   SendExtraNotification(notificationData, auth)
    // );

    // if (notificationResponse && notificationResponse.code === 200) {
    //   message.success("Notification sent successfully");
    //   // Fetch updated orders from the server
    //   fetchOrders();
    // }
    setChatModalVisible(false);
  };

  const fetchOrders = async () => {
    if (userData && Array.isArray(orders)) {
      dispatch(GetKitchenOrders(userData?.KitchenEmail));

      const sortedMenus = [...orders].sort((a, b) => {
        return moment(b.CreatedAt).valueOf() - moment(a.CreatedAt).valueOf();
      });

      const filteredOrdersForToday = sortedMenus.filter((order) => {
        const orderDate = moment(order.CreatedAt);
        return today === orderDate.date();
      });

      const updatedOrders = filteredOrdersForToday.map((order, index) => ({
        ...order,
        OrderNumber: index + 1,
      }));

      setOrderNumber(orderNumber + orders.length);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(fetchOrders, 2000);
    return () => clearInterval(intervalId);
  }, [auth]);

  useEffect(() => {
    if (lastProcessedOrder?.IsAttended === true) {
      setDoneAndPackagedOrders((prevDoneAndPackagedOrders) => [
        ...prevDoneAndPackagedOrders,
        lastProcessedOrder.OrderId,
      ]);
      setAttendedOrders((OrderAttended) => [
        ...OrderAttended,
        lastProcessedOrder.OrderId,
      ]);
      dispatch(setLastProcessedOrder(null));
    }
  }, [lastProcessedOrder]);

  const handleDoneAndPackagedButtonClick = async (order) => {
    if (order.IsAttended === true) {
      return;
    }
  
    // Determine message based on whether it's Elizade University
    let message;
    if (isElizadeUniversity()) {
      message = order.IsTakeAway
        ? `Your order with ID ${order.TrxRef} is ready for takeaway! Please pick it up with your cooler at the cafeteria.`
        : `Your order with ID ${order.TrxRef} is ready to be ate at the cafeteria! Please come to the cafeteria to enjoy your meal.`;
    } else {
      message = order.IsTakeAway
        ? `Using Takeaway. Your order with ID ${order.TrxRef} is ready for collection.`
        : `No Takeaway. Your order with ID ${order.TrxRef} is ready for collection.`;
    }
  
    // Prepare notification data
    let notificationData;
    if (userData && userData.Role === "basic") {
      notificationData = {
        KitchenId: userData.KitchenId,
        Title: `${userData.FirstName} (staff)`,
        UserId: order.UserId,
        Message: message,
        OrderId: order.TrxRef,
      };
    } else {
      notificationData = {
        KitchenId: userData.Id,
        Title: `${userData.KitchenName} - Order Done and Packaged`,
        UserId: order.UserId,
        Message: message,
        OrderId: order.TrxRef,
      };
    }
  
    // Send the notification
    dispatch(SendNotification(notificationData));
  };
  

  const ordersPerPage = 8;

  const handleNextPage = () => {
    setCurrentOrderIndex(currentOrderIndex + ordersPerPage);
  };

  const handlePrevPage = () => {
    setCurrentOrderIndex(Math.max(0, currentOrderIndex - ordersPerPage));
  };

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.play().catch((error) => {});
    }
  };

  const animateAudio = () => {
    playAudio();
    animationRef.current = requestAnimationFrame(animateAudio);
  };

  useEffect(() => {
    const storedLatestOrderTimestamp = localStorage.getItem(
      "latestOrderTimestamp"
    );
    const latestOrderTimestamp = storedLatestOrderTimestamp
      ? parseInt(storedLatestOrderTimestamp)
      : 0;

    // Sort the orders by creation time in descending order
    var currentOrderC;
    if (filteredOrders) {
      const sortedOrders = [...filteredOrders].sort(
        (a, b) => moment(b.CreatedAt).unix() - moment(a.CreatedAt).unix()
      );
      currentOrderC = sortedOrders[currentOrderIndex];
    }

    const isNewOrder =
      currentOrderC &&
      moment(currentOrderC.CreatedAt).unix() > latestOrderTimestamp;

    if (isNewOrder) {
      // Trigger Notification
      notification.info({
        message: "New Order!",
        description: `Order #${currentOrderC.TrxRef} has been received.`,
        duration: 0,
        onClose: () => {
          // Stop audio and animation when the notification is closed
          if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
          }
          if (animationRef.current) {
            cancelAnimationFrame(animationRef.current);
          }
        },
      });

      const audio = new Audio("/message_tone.mp3");
      audio.loop = true; // Set the loop property to true

      // Assign the audio element to the ref
      audioRef.current = audio;

      // Start continuous audio playback
      animateAudio();

      // Update LocalStorage with the latest timestamp
      localStorage.setItem(
        "latestOrderTimestamp",
        moment(currentOrderC.CreatedAt).unix().toString()
      );
    }
  }, [filteredOrders, currentOrderIndex]);

  const formatOrderDate = (dateString) => {
    // Create a new Date object from the date string
    const orderDate = new Date(dateString);

    // Define options for formatting the date
    const options = {
      weekday: "long", // Full name of the day of the week (e.g., "Friday")
      month: "long", // Full name of the month (e.g., "May")
      day: "numeric", // Numeric day of the month (e.g., 10th)
      year: "numeric", // Year (e.g., 2024)
      hour: "numeric", // Hour in 12-hour format (e.g., 3)
      minute: "numeric", // Minutes (e.g., 21)
      second: "numeric", // Seconds (e.g., 4)
      hour12: true, // 12-hour format with am/pm
    };

    // Format the date using the Intl.DateTimeFormat API
    const formattedDate = orderDate.toLocaleString("en-US", options);

    return formattedDate;
  };

  const renderOrderDate = (date) => {
    const orderDate = new Date(date);

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
      timeZone: "UTC", // Specify time zone to UTC to standardize the output
    };

    return orderDate.toLocaleString(undefined, options);
  };

  const handlePrint = (order) => {
    if (order) {
      const foodItemsList = order.Items.map(
        (foodItem, index) =>
          `<li key=${index}>${foodItem.Name} x${foodItem.Scoops} (₦${foodItem.Price})</li>`
      ).join("");

      const contentToPrint = `
        <h1>Order ID: ${order.TrxRef}</h1>
        <p>Date: ${renderOrderDate(order.CreatedAt)}</p>
        <h2>Food Items:</h2>
        <ul>
          ${foodItemsList}
        </ul>
      `;

      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
        <html>
          <head>
            <title>Print</title>
          </head>
          <body>
            ${contentToPrint}
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.print();
    }
  };

  return (
    <div
      style={{
        marginLeft: "7rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {filteredOrders?.length > 0 && (
        <div
          style={{
            marginBottom: "20px",
            display: "flex",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Input.Search
            placeholder="Search Order ID"
            value={searchQuery}
            onChange={handleSearchChange}
            style={{ width: "20rem", marginRight: "10px" }}
            allowClear
          />
          <Select
            style={{ width: "150px" }}
            placeholder="Filter"
            onChange={handleAttendedFilterChange}
          >
            <Select.Option value="attended">Attended</Select.Option>
            <Select.Option value="unattended">Unattended</Select.Option>
          </Select>
        </div>
      )}

      {filteredOrders?.length > 0 ? (
        <>
          <Collapse
            accordion
            style={{ width: "60rem", marginTop: "10px", marginLeft: "50px" }}
          >
            {filteredOrders
              ?.slice(currentOrderIndex, currentOrderIndex + ordersPerPage)
              ?.filter(
                (order) =>
                  order.TrxRef.toString().includes(searchQuery) &&
                  (attendedFilter === null ||
                    order.IsAttended === (attendedFilter === "attended"))
              )
              .map((order, index) => (
                <Panel
                  key={order.TrxRef}
                  header={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      OrderID {order.TrxRef}
                      <ChatCircleDots
                        size={26}
                        color="green"
                        style={{ cursor: "pointer" }}
                        onClick={handleChatIconClick}
                      />
                      <span>
                        {order.IsAttended === true && (
                          <Tag color="green" style={{ marginLeft: "10px" }}>
                            Attended
                          </Tag>
                        )}
                      </span>
                      <span style={{ marginLeft: "auto", fontSize: "14px" }}>
                        {/* {moment(order.CreatedAt).format(
                          "dddd, MMMM Do YYYY, h:mm:ss a"
                        )} */}
                        <Printer
                          size={30}
                          color="green"
                          style={{ cursor: "pointer" }}
                          onClick={() => handlePrint(order)}
                        />
                        {formatOrderDate(order.CreatedAt)}
                      </span>
                    </div>
                  }
                >
                  <Card style={cardStyle}>
                    <Descriptions bordered column={1}>
                      <Descriptions.Item label="Order ID">
                        {order.TrxRef}
                      </Descriptions.Item>
                      <Descriptions.Item label="Customer Description">
                        {order.Description}
                      </Descriptions.Item>
                      <Descriptions.Item label="PhoneNumber">
                        {order.UserPhone}
                      </Descriptions.Item>
                      {isElizadeUniversity () ? (
                        <Descriptions.Item label="Process">
                          {order.IsTakeAway ? "Using my cooler" : "Eat at cafe"}
                        </Descriptions.Item>
                      ):(
                        <Descriptions.Item label="Takeaway">
                          {order.IsTakeAway ? "Yes" : "No"}
                        </Descriptions.Item>
                      )}
                    </Descriptions>
                    <Divider />
                    <List
                      dataSource={order.Items}
                      renderItem={(dishItem, index) => (
                        <List.Item key={index}>
                          <div style={{ fontWeight: "bold" }}>
                            {dishItem.Name} x{dishItem.Scoops}
                          </div>
                          <div>₦{dishItem.Price}</div>
                        </List.Item>
                      )}
                    />
                    <Divider />
                    <div style={styles.totalSum}>
                      <h2>Total:</h2>
                      <h2 style={styles.totalPrice}>₦{order.TotalAmount}</h2>
                    </div>
                    <Divider />
                    <div style={styles.buttonContainer}>
                      {/* {!attendedOrders.includes(order.TrxRef) &&
                        !doneAndPackagedOrders.includes(order.TrxRef) &&
                        order.IsAttended === false && (
                          <Button
                            style={styles.seeNextButton}
                            onClick={() =>
                              handleDoneAndPackagedButtonClick(order)
                            }
                          >
                            Notify User with order #{order.TrxRef}
                          </Button>
                        )} */}
                      {order.IsAttended === false && (
                        <Button
                          style={styles.seeNextButton}
                          onClick={() =>
                            handleDoneAndPackagedButtonClick(order)
                          }
                        >
                          Notify User with order #{order.TrxRef}
                        </Button>
                      )}
                    </div>
                    <Modal
                      title="Send this user a message"
                      open={isChatModalVisible}
                      onCancel={handleModalCancel}
                      footer={[
                        <Button key="cancel" onClick={handleModalCancel}>
                          Cancel
                        </Button>,
                        <Button
                          key="send"
                          type="primary"
                          onClick={() => handleSendMessage(order)}
                        >
                          Send
                        </Button>,
                      ]}
                    >
                      <Input.TextArea
                        rows={4}
                        value={messageInput}
                        onChange={handleInputChange}
                        placeholder="Type your message here..."
                      />
                    </Modal>
                  </Card>
                </Panel>
              ))}
          </Collapse>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Button onClick={handlePrevPage} disabled={currentOrderIndex === 0}>
              Previous Page
            </Button>
            <Button
              onClick={handleNextPage}
              disabled={
                currentOrderIndex + ordersPerPage >= filteredOrders.length
              }
            >
              Next Page
            </Button>
          </div>
        </>
      ) : (
        <div
          style={{
            textAlign: "center",
            fontSize: "2rem",
            color: "grey",
            marginRight: "7%",
            marginTop: "20%",
          }}
        >
          {filteredOrders?.length === 0
            ? "No delicious orders have arrived just yet. The kitchen awaits its first culinary masterpiece! 🍔🍕🍝"
            : "Loading..."}
        </div>
      )}
    </div>
  );
};

const cardStyle = {
  margin: "1rem",
  width: "45rem",
  height: "740px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  alignItems: "center",
  marginLeft: "6rem",
  overflowY: "auto",
  // marginRight: '8rem',
};

const descriptionStyle = {
  whiteSpace: "pre-wrap",
  maxWidth: "300px",
};

const styles = {
  totalSum: {
    flexDirection: "row",
    display: "flex",
  },
  totalPrice: {
    marginLeft: "auto",
    fontWeight: "bold",
  },
  buttonContainer: {
    display: "flex",
    paddingBottom: 30,
    flexDirection: "row",
    marginLeft: "10%",
  },
  seeNextButton: {
    // marginLeft: 'auto',
    marginRight: "10%",
    background: "#006400",
    borderColor: "#52c41a",
    color: "white",
    width: "600px",
    height: "40px",
    marginLeft: "10px",
  },
};

export default Orders;

// <Descriptions.Item label="Order Number">
//                         #{filteredOrders.length - index}
//                       </Descriptions.Item>

// Order #{filteredOrders.length - index}