import React, { useState } from "react";
import Nav from "./Nav";
import { selectKitchen, useAppSelector } from "../../../../Store/store";
import QuildChart from "../Necessities/QuildChart";
import { Table, Modal } from "antd";
import TableChart from "../Necessities/TableChart";

function Home({ Toggle }) {
  const {
    allAdminKitchen,
    adminUniversity,
    adminAmount,
    adminMonthlyRev,
    adminWeeklyAmount,
    adminDailyRev,
    adminAllUsers,
    adminTodayUsers
  } = useAppSelector(selectKitchen);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedKitchen, setSelectedKitchen] = useState(null);

  const currentDate = new Date();
  const previousDate = new Date(currentDate);
  previousDate.setDate(previousDate.getDate() - 1);

  const handleKitchenClick = (record) => {
    setSelectedKitchen(record);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const totalQuildAmount = adminMonthlyRev?.reduce((total, kitchen) => {
    return (
      total + kitchen.data.reduce((sum, item) => sum + item.quildProfit, 0)
    );
  }, 0);

  const totalWeekAmount = adminWeeklyAmount?.reduce((total, kitchen) => {
    return total + kitchen.ThisWeekTotalAmount;
  }, 0);

  const totalDailyAmount = adminDailyRev?.reduce((total, kitchen) => {
    return total + kitchen.ThisWeekTotalAmount;
  }, 0);

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Kitchen Name(s)",
      dataIndex: "KitchenName",
      key: "KitchenName",
    },
    {
      title: "University",
      dataIndex: "KitchenUniversity",
      key: "KitchenUniversity",
    },
    {
      title: `Kitchen Revenue (Yesterday: ${previousDate.toLocaleDateString()})`,
      dataIndex: "PrevTotalamount",
      key: "PrevTotalamount",
      render: (text) => `₦${text}`,
    },
    {
      title: `Kitchen Revenue (Today: ${currentDate.toLocaleDateString()})`,
      dataIndex: "TodayTotalAmount",
      key: "TodayTotalAmount",
      render: (text) => `₦${text}`,
    },
    {
      title: "Bank Account details",
      dataIndex: "BankAccountNumber",
      key: "BankAccountNumber",
      render: (text, record) => `${text} - ${record.BankName}`,
    },
  ];

  const dataSource = adminAmount?.map((kitchen, index) => ({
    ...kitchen,
    index: index + 1,
  }));

  return (
    <div className="px-3">
      <Nav Toggle={Toggle} />
      <div className="container-fluid" style={{ marginLeft: "1%" }}>
        <div className="row g-3 my-2">
          <div className="col-md-3 p-2">
            <div className="p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded">
              <div>
                {/* Used all Admin Kitchen */}
                <h3 className="fs-2">{allAdminKitchen?.length}</h3>
                <p className="fs-5">Registered Kitchen(s)</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 p-2">
            <div
              className="p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded"
              style={{ height: "100%" }}
            >
              <div>
                {/* Used adminUniversity endpoint */}
                <h3 className="fs-2">{adminUniversity?.length}</h3>
                <p className="fs-5">Universities</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 p-2">
            <div className="p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded">
              <div className="col">
                <h3 className="fs-2">₦{totalDailyAmount}</h3>
                <p className="fs-5">Daily Finance</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 p-2">
            <div className="p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded">
                <div className="col">
                  <h3 className="fs-2">₦{totalWeekAmount}</h3>
                  <p className="fs-5">Weekly Finance</p>
                </div>
            </div>
          </div>
          <div className="col-md-3 p-2">
            <div className="p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded">
                <div className="col">
                  <h3 className="fs-2">₦{totalQuildAmount}</h3>
                  <p className="fs-5">Monthly Finance</p>
                </div>
            </div>
          </div>
          <div className="col-md-3 p-2">
            <div className="p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded">
                <div className="col">
                  <h3 className="fs-2">{adminTodayUsers?.TodaysUser}</h3>
                  <p className="fs-5">QuicKee Daily User</p>
                </div>
            </div>
          </div>
          <div className="col-md-3 p-2">
            <div className="p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded">
                <div className="col">
                  <h3 className="fs-2">{adminAllUsers?.TodaysUser}</h3>
                  <p className="fs-5">All QuicKee Users</p>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h1 className="text-white fs-3" style={{ marginLeft: "2%" }}>
          QuicKee Chart
        </h1>
        <QuildChart />
      </div>

      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false} // Disable pagination if needed
        rowKey={"KitchenId"}
        bordered
        caption={() => (
          <caption className="text-white fs-4">Other necessary details</caption>
        )}
        style={{
          marginLeft: "2%",
          marginTop: "5%",
          marginBottom: "3%",
          width: "95%",
          height: "100%",
        }}
        onRow={(record) => ({
          onClick: () => handleKitchenClick(record),
        })}
      />

      <Modal
        title={selectedKitchen?.KitchenName}
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        {/* Modal content here */}
        <p>Amount To Transfer: ₦{selectedKitchen?.TodayTotalAmount}</p>
        <p>
          Bank Account details: {selectedKitchen?.BankAccountNumber} -{" "}
          {selectedKitchen?.BankName}
        </p>
        <TableChart selectedKitchen={selectedKitchen} />
      </Modal>
    </div>
  );
}

export default Home;
