import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import AdminSidebar from "./Others/Sidebar";
import Home from "./Others/Home";
// import { useAppDispatch } from "../../../Store/store";
// import {
//   AdminGetAllKitchen,
//   AdminGetAllUniversity,
//   AdminGetAllAmount,
//   AdminGetMonthlyRev,
//   AdminGetWeeklyAmount,
//   AdminGetDailyRev,
// } from "../../../Features/kitchenSlice";

function AdminBoard() {
  // const dispatch = useAppDispatch();
  const [toggle, setToggle] = useState(true);
  const Toggle = () => {
    setToggle(!toggle);
  };

  // useEffect(() => {
  //   const fetch = setInterval(() => {
  //     //For Registered Kitchen
  //     dispatch(AdminGetAllKitchen());

  //     //For the University Card
  //     dispatch(AdminGetAllUniversity());

  //     //for the table
  //     dispatch(AdminGetAllAmount());

  //     //QuicKee Finances Per Month
  //     dispatch(AdminGetMonthlyRev());

  //     //QuicKee Finance Per Week
  //     dispatch(AdminGetWeeklyAmount());

  //     //QuicKee Finance Per Day
  //     dispatch(AdminGetDailyRev());
  //   }, 300000);

  //   return () => clearInterval(fetch);
  // }, [dispatch]);

  return (
    <div className="container-fluid bg-secondary min-vh-100">
      <div className="row">
        {toggle && (
          <div className="col-3 col-md-2 bg-white vh-100 position-fixed">
            <AdminSidebar />
          </div>
        )}
        {toggle && <div className="col-3 col-md-2"></div>}
        <div className="col">
          <Home Toggle={Toggle} />
        </div>
      </div>
    </div>
  );
}

export default AdminBoard;
