import React, { useState } from "react";
import "./styles.css";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function AdminSidebar() {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    setShowModal(true);
  };

  const handleConfirmLogout = () => {
    setShowModal(false);
    navigate('/quildSignin')
  };

  const handleCancelLogout = () => {
    setShowModal(false);
  };

  return (
    <div className="bg-f2f2f2 sidebar p-2">
      <div className="m-2">
        <i className="bi bi-person-circle me-3 fs-4"></i>
        <span className="brand-name fs-4">Admin</span>
      </div>
      <hr className="text-dark" />
      <div className="list-group list-group-flush">
        <a className="list-group-item py-2">
          <i className="bi bi-bar-chart fs-5 me-3"></i>
          <span>Dashboard</span>
        </a>
        <a className="list-group-item py-2" onClick={handleLogout}>
          <i className="bi bi-power fs-5 me-3"></i>
          <span>Log Out</span>
        </a>
      </div>
      <Modal show={showModal} onHide={handleCancelLogout}>
        <Modal.Header closeButton>
          <Modal.Title>Logout Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to logout?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelLogout}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmLogout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AdminSidebar;
