import React, { useEffect, useState } from "react";
import { Card, Table } from "antd";
import { GetKitchenOrders } from "../Features/kitchenSlice";
import { selectKitchen, useAppSelector, useAppDispatch } from "../Store/store";

function RecentOrders() {
  const dispatch = useAppDispatch();
  const { userData, orders, socketIOmessages } = useAppSelector(selectKitchen);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    const fetchRecentOrders = async () => {
      if (orders && Array.isArray(orders)) {
        const currentDate = new Date();
        const currentDay = currentDate.getDate();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        // Filter and display only paid orders
        const recentOrders = orders.filter((order) => {
          const orderDate = new Date(order.CreatedAt);
          return (
            orderDate.getDate() === currentDay &&
            orderDate.getMonth() === currentMonth &&
            orderDate.getFullYear() === currentYear &&
            order.IsPaid === true
          );
        });

        recentOrders.sort(
          (a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt)
        );
        const firstFiveRecentOrders = recentOrders.slice(0, 5);
        setDataSource(firstFiveRecentOrders);
      }
    };

    fetchRecentOrders();
  }, [userData, dispatch, orders]);

  useEffect(() => {
    if (userData) {
      dispatch(GetKitchenOrders(userData?.KitchenEmail));
    }
  }, [socketIOmessages]);

  const columns = [
    {
      title: "Descriptions",
      dataIndex: "Description",
      key: "Description",
    },
    {
      title: "Order ID",
      dataIndex: "TrxRef",
      key: "TrxRef",
    },
    {
      title: "Title",
      dataIndex: "Items",
      key: "Items",
      render: (foodDetails) => (
        <ul>
          {foodDetails &&
            foodDetails.map((foodItem, index) => (
              <li key={index}>
                {foodItem.Name} x{foodItem.Scoops} (₦{foodItem.Price})
              </li>
            ))}
        </ul>
      ),
    },
    {
      title: "Total Price",
      dataIndex: "TotalAmount",
      key: "TotalAmount",
      render: (text) => `₦${text}`,
    },
  ];

  return (
    <Card
      title="Recent Orders"
      className="w-50 border rounded shadow-sm mt-0"
      style={{ height: "330px", overflow: "auto" }}
    >
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{ y: 150, maxHeight: "70vh" }}
        className="w-100"
      />
    </Card>
  );
}

export default RecentOrders;
