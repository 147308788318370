import { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import { useDispatch } from "react-redux";
import { setSocketIOmessages } from "./Features/kitchenSlice";

const socket = io("https://srv516319.hstgr.cloud:85", {
  // query: { userId: "acZm32Qgh" },
  transports: ["websocket", "polling", "webtransport"],
  reconnection: true,
  reconnectionAttempts: Infinity,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  timeout: 5000,
});

const UseSocketIO = (userId, shouldReconnect) => {
  const [isConnected, setIsConnected] = useState(false);
  const dispatch = useDispatch();
  const socketRef = useRef(null);
  const [connectionAttempts, setConnectionAttempts] = useState(0);
  const MAX_CONNECTION_ATTEMPTS = Infinity;

  useEffect(() => {
    if (!userId) return;

    socketRef.current = socket;

    socket.on("connect", () => {
      setIsConnected(true);
      setConnectionAttempts(0);
      socket.emit("userId: ", { userId: userId });
      //console.log("Connected to server");
    });

    socket.on("transaction-success", (data) => {
      //console.log("Received data from socketIO: ", data);
      dispatch(setSocketIOmessages(data));
    });

    socket.on("connect_error", (err) => {
      // console.error("Connection error:", err);
      // retryConnection(socket);
    });

    socket.on("disconnect", (reason) => {
      setIsConnected(false);
      //console.log("Disconnected:", reason);
      // retryConnection(socket);
    });

    socket.on("reconnecting", (reason) => {
      //console.log("Reconnecting...", reason);
    });

    // Function to retry connection
    const retryConnection = (socket) => {
      if (connectionAttempts < MAX_CONNECTION_ATTEMPTS) {
        //console.log(`Retrying connection attempt ${connectionAttempts + 1}...`);
        setConnectionAttempts((prevAttempts) => prevAttempts + 1);
        setTimeout(() => {
          if (shouldReconnect) {
            //console.log("reconnection triggered from App.js");
            socket.connect(); // Retry connection after a delay
          }
        }, 2000); // Adjust the delay as needed
      } else {
        //console.error("Exceeded maximum connection attempts.");
      }
    };

    return () => {
      socket.disconnect();
    };
  }, [userId, dispatch, shouldReconnect, MAX_CONNECTION_ATTEMPTS]);

  return { isConnected };
};

export default UseSocketIO;
