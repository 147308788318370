import React, { useState, useCallback } from "react";
import { Button, Input, Typography } from "antd";

const { Title } = Typography;

function DeleteAccount() {
  const [fullName, setFullName] = useState("");
  const [reason, setReason] = useState("");

  const handleNameChange = (e) => {
    setFullName(e.target.value);
  };

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleFeedback = useCallback(() => {
    const gmailAddress = 'noreply4quickee@gmail.com';
    const subject = 'Account Deletion Request for QuicKee App';
    const body = `Full Name: ${fullName} Reason: ${reason}`;

    const mailtoLink = `mailto:${gmailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  }, [fullName, reason]);

  // Disable button if either fullName or reason is empty
  const isButtonDisabled = !fullName || !reason;

  return (
    <div className="glass-morphism" style={{ width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", background: "#f0f2f5" }}>
      <div style={{ position: "fixed", top: 0, left: 0, width: "100%", backgroundColor: "#fff", borderBottom: "1px solid #ddd" }}>
        <Title level={3} style={{ margin: 0, padding: "10px 20px", cursor: "pointer", color: "#c45628", fontFamily: 'cursive' }} onClick={() => window.location.reload()}>QuicKee</Title>
      </div>
      <div className="rectangle" style={{ padding: "20px", borderRadius: "10px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", background: "#fff", marginBottom: "20px" }}>
        <Title level={4} style={{ textAlign: "center", marginBottom: "20px" }}>Request to Delete Account</Title>
        <form style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <div style={{ marginBottom: "10px", width: "80%" }}>
            <label>Full Name Used On the Registered Account:</label>
            <Input 
                type="text" 
                id="Name" 
                name="Name" 
                placeholder="Enter your full name" 
                value={fullName} 
                onChange={handleNameChange}
                style={{ width: "100%", borderRadius: 5 }}
                required
            />
          </div>
          <div style={{ marginBottom: "10px", width: "80%" }}>
            <label>Reason for deletion:</label>
            <Input.TextArea 
                id="Reason" 
                name="Reason" 
                placeholder="Enter your reason and add the email you used when registering the account" 
                value={reason} 
                onChange={handleReasonChange} 
                style={{ width: "100%", borderRadius: 5 }}
                required
            />
          </div>
          <Button type="primary" onClick={handleFeedback} style={{ width: "80%", borderRadius: 5, marginTop: "10px" }} disabled={isButtonDisabled}>Submit Request</Button>
        </form>
      </div>
    </div>
  );
}

export default DeleteAccount;