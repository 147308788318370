import "./style.css";
import React, {useEffect} from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import { selectKitchen, useAppSelector, useAppDispatch, } from "../../../../Store/store";
import { AdminGetWeeklyAmount, AdminGetMonthlyRev } from "../../../../Features/kitchenSlice";

const TableChart = ({selectedKitchen}) => {
    const dispatch = useAppDispatch();
    const { adminMonthlyRev } = useAppSelector(selectKitchen);

    useEffect(() => {
      if (selectedKitchen) {
        dispatch(AdminGetMonthlyRev(selectedKitchen.KitchenId));
      }
    }, [selectedKitchen, dispatch]);
  
    // Filter data for the selected kitchen
    const monthlyData = adminMonthlyRev?.filter((entry) => entry.KitchenId === selectedKitchen?.KitchenId) || [];

    const currentMonth = monthlyData.reduce((acc, entry) => {
      const data = entry.data.map((item) => {
        const date = new Date(item.month);
        const month = date.toLocaleString('default', { month: 'long' });
        return {
          month: month,
        };
      });
      return [...acc, ...data];
    }, []) || [];
    
    
    const totalAmountData = monthlyData.reduce((acc, entry) => {
      const data = entry.data.map((item) => ({
        totalAmount: item.totalAmount,
      }));
      return [...acc, ...data];
    }, []) || [];
  
    const quildProfitData = monthlyData.reduce((acc, entry) => {
      const data = entry.data.map((item) => ({
        quildProfit: item.quildProfit,
      }));
      return [...acc, ...data];
    }, []) || [];

    const data = currentMonth.map((monthData, index) => ({
      name: monthData.month,
      totalAmount: totalAmountData[index]?.totalAmount || 0,
      quildProfit: quildProfitData[index]?.quildProfit || 0
    }));    
    
    
  return (
    <BarChart
      width={500}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 1,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="totalAmount" fill="#8884d8" />
      <Bar dataKey="quildProfit" fill="#82ca9d" />
    </BarChart>
  );
}
export default TableChart