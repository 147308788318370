import React from "react";
import { Button, Typography } from "antd";

const { Title } = Typography;

function DownloadApk() {
  const handleDownload = () => {
    // Replace 'your-apk-file-name.apk' with the actual name of your APK file
    const apkFilePath = '/QuicKee-v1.apk';
    const a = document.createElement('a');
    a.href = apkFilePath;
    a.download = 'QuicKee.apk';
    a.click();
  };

  return (
    <div className="glass-morphism" style={{ width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", background: "#f0f2f5" }}>
      <div style={{ position: "fixed", top: 0, left: 0, width: "100%", backgroundColor: "#fff", borderBottom: "1px solid #ddd" }}>
        <Title level={3} style={{ margin: 0, padding: "10px 20px", cursor: "pointer", color: "#c45628", fontFamily: 'cursive' }} onClick={() => window.location.reload()}>QuicKee</Title>
      </div>
      <div className="download-container" style={{ padding: "20px", borderRadius: "10px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", background: "#fff", marginBottom: "20px" }}>
        <Title level={4} style={{ textAlign: "center", marginBottom: "20px" }}>Download APK</Title>
        <Button type="primary" onClick={handleDownload} style={{ marginBottom: "20px", marginLeft: '35%' }}>Download</Button>
        <p style={{ textAlign: "center", fontSize: "14px", color: "#888" }}>Click the button above to download the QuicKee APK file.</p>
      </div>
    </div>
  );
}

export default DownloadApk;
