import React, { useEffect, useState } from "react";
import { Card } from "antd";
import { Line } from "react-chartjs-2";
import { GetKitchenOrders } from "../Features/kitchenSlice";
import { selectKitchen, useAppSelector, useAppDispatch } from "../Store/store";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function DashboardChart({ loading }) {
  const dispatch = useAppDispatch();
  const { userData, orders, dailyRevenueInMonth } =
    useAppSelector(selectKitchen);
  const [dailyRevenues, setDailyRevenues] = useState([]);
  // const [monthlyRevenue, setMonthlyRevenue] = useState(null);
  // const [historicalData, setHistoricalData] = useState([]);

  const getMonthYear = () => {
    const currentDate = new Date();
    const monthYearKey = `${currentDate.toLocaleString("default", {
      month: "long",
    })} ${currentDate.getFullYear()}`;
    return monthYearKey;
  };

  useEffect(() => {
    if (!orders) {
      dispatch(GetKitchenOrders(userData?.KitchenEmail));
    }
  }, [dispatch, orders, userData]);

  useEffect(() => {
    const fetchDailyRevenues = async () => {
      if (dailyRevenueInMonth) {
        // const paidOrders = orders.filter((order) => order.IsPaid);
        const dailyRevenueData = calculateDailyRevenue(dailyRevenueInMonth);
        setDailyRevenues(dailyRevenueData);

        // const totalRevenueForMonth = dailyRevenueData.reduce(
        //   (total, daily) => total + daily,
        //   0
        // );
        // setMonthlyRevenue(totalRevenueForMonth);
      }
    };

    fetchDailyRevenues();
  }, [userData, dailyRevenueInMonth]);

  const calculateDailyRevenue = (orders) => {
    const dailyRevenueData = new Array(31).fill(0);

    // orders.forEach((order) => {
    //   const orderDate = new Date(order.CreatedAt);
    //   const dayOfMonth = orderDate.getDate();
    //   const orderTotal = order.TotalAmount || 0;
    //   dailyRevenueData[dayOfMonth - 1] += parseFloat(orderTotal);
    // });
    orders?.dailyDatas?.forEach((dailyRev) => {
      const dayOfMonth = new Date(dailyRev?.date).getDate();
      // console.log(dayOfMonth, " day");
      dailyRevenueData[dayOfMonth - 1] += parseFloat(dailyRev?.dailyrevenue);
    });

    return dailyRevenueData;
  };

  const revenueData = {
    labels: Array.from({ length: 31 }, (_, i) => (i + 1).toString()),
    datasets: [
      {
        label: "Daily Revenue",
        data: dailyRevenues,
        borderColor: "rgb(0, 0, 0)",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        yAxisID: "y",
      },
    ],
  };

  // console.log("dailyRevenues: ", dailyRevenues);

  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      title: {
        display: true,
        text: `Daily Revenue For ${getMonthYear()}`,
        font: { size: 18 },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        type: "category",
        grid: {
          display: false,
        },
        ticks: {
          font: { size: 14 },
        },
      },
      y: {
        type: "linear",
        ticks: {
          font: { size: 14 },
        },
      },
    },
  };

  const cardContainerStyle = {
    width: "102%",
    // overflow: "auto",
    marginLeft: "2%",
  };

  const cardStyle = {
    border: "1px solid #e8e8e8",
    borderRadius: "5px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    width: "100%",
  };

  return (
    <div style={cardContainerStyle}>
      <Card
        title="My Restaurant Revenue"
        className="rounded shadow-sm mt-0"
        style={cardStyle}
      >
        <div style={{ height: "225px" }} className="w-100">
          {loading ? (
            <div>Loading Chart...</div>
          ) : (
            <>
              <Line options={options} data={revenueData} />
              {dailyRevenueInMonth ? (
                <p className="text-center mt-0">
                  Total Monthly Revenue: ₦
                  {dailyRevenueInMonth?.totalMonthlyAmount?.toFixed(2)}
                </p>
              ) : null}
            </>
          )}
        </div>
      </Card>
    </div>
  );
}

export default DashboardChart;
