import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { AdminGetMonthlyRev } from "../../../../Features/kitchenSlice";
import { selectKitchen, useAppDispatch, useAppSelector } from "../../../../Store/store";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function QuildChart({ loading }) {
  const dispatch = useAppDispatch();
  const { adminMonthlyRev } = useAppSelector(selectKitchen);

  useEffect(() => {
    dispatch(AdminGetMonthlyRev())
  }, []);

  const monthlyData = adminMonthlyRev || [];

  const totalAmountData = monthlyData.reduce((acc, entry) => {
    const data = entry.data.map((item) => ({
      kitchen: entry.KitchenName,
      month: new Date(item.month).toLocaleString("default", {
        month: "long",
        year: "numeric",
      }),
      totalAmount: item.totalAmount,
    }));
    return [...acc, ...data];
  }, []) || [];

  const quildProfitData = monthlyData.reduce((acc, entry) => {
    const data = entry.data.map((item) => ({
      kitchen: entry.KitchenName,
      month: new Date(item.month).toLocaleString("default", {
        month: "long",
        year: "numeric",
      }),
      quildProfit: item.quildProfit,
    }));
    return [...acc, ...data];
  }, []) || [];

  const revenueData = {
    labels: totalAmountData?.map(data => data?.kitchen),
    datasets: [
      {
        label: "Total Amount",
        data: totalAmountData,
        borderColor: "rgb(0, 0, 255)",
        backgroundColor: "rgba(0, 0, 255, 0.5)",
        yAxisID: "y",
        parsing: {
          yAxisKey: "totalAmount",
          xAxisKey: "kitchen",
        },
      },
      {
        label: "Quild Profit",
        data: quildProfitData,
        borderColor: "rgb(255, 0, 0)",
        backgroundColor: "rgba(255, 0, 0, 0.5)",
        yAxisID: "y",
        parsing: {
          yAxisKey: "quildProfit",
          xAxisKey: "kitchen",
        },
      },
    ],
  };

  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      title: {
        display: true,
        text: `Monthly Revenue For QuicKee`,
        font: { size: 18 },
      },
      legend: {
        display: true,
        position: "bottom",
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            const data = context.dataset.data[context.dataIndex];
            let label = `${data.kitchen} (${data.month}):`;
            if (data.totalAmount !== undefined) {
              label += `\nTotal Amount - ₦${data.totalAmount}`;
            }
            if (data.quildProfit !== undefined) {
              label += `\nQuild Profit - ₦${data.quildProfit}`;
            }
            return label;
          }
        },
      },
    },
    scales: {
      x: {
        type: "category",
        labels: totalAmountData.map(data => data.kitchen), // Use kitchen names as x-axis labels
        grid: {
          display: false,
        },
        ticks: {
          font: { size: 14 },
        },
      },
      y: {
        type: "linear",
        grid: {
          display: false,
        },
        ticks: {
          font: { size: 14 },
        },
      },
    },
  };    

  const cardContainerStyle = {
    width: "48%",
    // overflow: "auto",
    marginLeft: '2%',
    backgroundColor: "white",
    borderRadius: "5px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  };

  const chartContainerStyle = {
    width: "100%",
    height: "300px",
  };

  return (
    <div style={cardContainerStyle}>
        <div style={chartContainerStyle} className="w-100">
          {loading ? (
            <div>Loading Chart...</div>
          ) : (
            <>
              <div style={{marginLeft: "3%"}}>
                <Line options={options} data={revenueData} />
              </div>
            </>
          )}
        </div>
    </div>
  );
}

export default QuildChart;
